import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogOverlay,
  } from "@chakra-ui/react";
  import { useAlertContext } from "../context/alertContext";
  import { useRef } from "react";
  
  /**
   * This is a global component that uses context to display a global alert message.
   */
  function Alert() {
    const { isOpen, type, message, onClose } = useAlertContext();
    const cancelRef = useRef();

    const colors = {
      'success': '#81C784',
      'error': '#FF8A65',
      'info': "#E2E8F0",
    }
    const headings = {
      'success': 'Success',
      'error': 'Error',
      'info': "Info",
    }
  
    return (
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >

      
        <AlertDialogOverlay>
          <AlertDialogContent py={4} backgroundColor={colors[type]}>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {headings[type]}
            </AlertDialogHeader>
            <AlertDialogBody>{message}</AlertDialogBody>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    );
  }
  
  export default Alert;
  