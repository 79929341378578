import {
  Box,
  Flex,
  Heading,
  VStack,
  Text,
  Stack,
  HStack,
  StackDivider,
  IconButton,
  Spacer,
} from "@chakra-ui/react";

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import { customerTopProducts, customerProducts } from "../data/mock";

import React, { useState, useRef, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { Button, ButtonGroup } from "@chakra-ui/react";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { SearchIcon, AddIcon, MinusIcon, WarningIcon } from "@chakra-ui/icons";
import { Card, CardHeader, CardBody, CardFooter } from "@chakra-ui/react";

//API Generate Order mockup
import useSubmit from "../hooks/useSubmit";
// Alerts
import {useAlertContext} from "../context/alertContext";


function DropZonePlaceholder() {
  return (
    <VStack
      spacing={3}
      border="dotted"
      borderRadius="lg"
      padding={10}
      width="full"
    >
      <AddIcon />
      <Text>Drag and drop products here</Text>
    </VStack>
  );
}

function SelectedCustomer({customer}) {
    return (
        <Card variant="filled">
            <CardHeader>
                <Heading size="md">
                  Selected Customer
                </Heading>
                </CardHeader>
                <CardBody>
                <Text
                  pt="2"
                  fontSize="sm"
                  fontWeight="bold"
                  id="selectedCustomer"
                >
                  {customer ? (
                    `${customer.code} - ${customer.name}`
                  ) : (
                    <Text>Please find a User using the Search prompt 👆</Text>
                  )}
                </Text>
                </CardBody>
                </Card>
    );
}

function TopProductsList({ sourceItems }) {
  const [topSearch, setTopSearch] = useState(true);
  const searchPlaceholder = topSearch ? "Search Top Products" : "Search All Products";

  return (
    <>
      <Card mt={10}>
        <CardHeader textAlign="left">
          <Flex align="baseline">
            <Heading size="md">Products</Heading>
            <Spacer />
            <Stack direction='row' spacing={1} align='center'>
            <Button colorScheme="teal" variant={topSearch ? "solid" : "outline" } onClick={() => {setTopSearch(true)}}>Top</Button>
            <Button colorScheme="teal" variant={topSearch ? "outline" : "solid" } onClick={() => {setTopSearch(false)}}>All</Button>
            </Stack>
          </Flex>
        </CardHeader>
        <Box padding={5}>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<SearchIcon color="gray.300" size="lg" />}
            />
            <Input placeholder={searchPlaceholder} size="lg" />
          </InputGroup>
        </Box>
        <CardBody>
          <Box textAlign="center">
            {topSearch ? (
              <Text fontSize="sm">Top products ↓</Text>
            ) : (
              <Text fontSize="sm">All products ↓</Text>
            )}
          </Box>
          <Stack spacing="4">
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {sourceItems.map((item, index) => (
                    <Draggable
                      key={item.code}
                      draggableId={item.code}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <Box
                          textAlign="left"
                          shadow="md"
                          key={item.code}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <Heading size="md">{item.description}</Heading>
                          <Text fontSize="sm" color="gray.600">
                            SKU {item.code}
                          </Text>
                          <Text
                            pt="2"
                            fontSize="sm"
                            color="green.300"
                            fontWeight="bold"
                          >
                            Purchased before {item.qty} pcs
                          </Text>
                        </Box>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </Stack>
        </CardBody>
      </Card>
    </>
  );
}

function SelectedProductsList({ selectedItems, customer }) {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [quantities, setQuantities] = useState({});
  const { isLoading, response, submit} = useSubmit();
  const { onOpen } = useAlertContext();

  useEffect(() => {
    let updatedQuantities = quantities;
    selectedItems.forEach((product) => {
      if (!(product.code in quantities)) {
        updatedQuantities[product.code] = 1;
      }
    });
    setQuantities(updatedQuantities);

    let result = selectedItems.map((product) => ({
      itemId: product.itemId,
      code: product.code,
      description: product.description,
    }));
    setSelectedProducts(result);
  }, [selectedItems]);

  useEffect(() => {onOpen('info', 'Please drag&drop products from the left to the right to create a new order!')}
  ,[])

  const updateQuantity = (event, productCode) => {
    let updatedQuantities = { ...quantities };

    if (productCode in quantities) {
      if (event.target.value >= 0)
        updatedQuantities[productCode] = event.target.value;
    }
    setQuantities(updatedQuantities);
  };

  const increaseQuantity = (productCode) => {
    let updatedQuantities = { ...quantities };
    if (productCode in quantities)
      updatedQuantities[productCode] =
        parseInt(updatedQuantities[productCode]) + 1;
    setQuantities(updatedQuantities);
  };
  const decreaseQuantity = (productCode) => {
    let updatedQuantities = { ...quantities };

    if (productCode in quantities) {
      if (updatedQuantities[productCode] > 1)
        updatedQuantities[productCode] =
          parseInt(updatedQuantities[productCode]) - 1;
    }
    //else updatedProducts.splice(index, 1);

    setQuantities(updatedQuantities);
  };

  const generateOrder = () => {
    // var raw =
    //   '{\n    "customer_id": "420bc17c-e104-49e0-9c85-af927a6e589a",\n    "products": [\n        {\n         "product_id": "e5463560-4226-403c-853d-a0103bc12fdfg1231a",\n         "quantity": 2\n         },\n         {\n         "product_id": "e5463560-4226-403c-853d-a0103bc12fdfg1231a",\n         "quantity": 2\n         },\n    ]\n}';

    let products = selectedProducts.map((product) => {
      return { product_id: product.itemId, quantity: quantities[product.code] };
    });
    let payload = {
      customer_id: customer.id,
      products: products,
    };
    let raw = JSON.stringify(payload);
    console.log(raw);

    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
      mode: "no-cors",
      headers: { Authentication: "Bearer b469bcaf-0091-43ec-b231-0091" },
    };

    fetch(
      "https://tom-it.postman.co/workspace/6f87b490-4057-4ef3-8733-f096ffc777ab/mock/b469bcaf-0091-43ec-b231-5f04c6e08986/api/order/create",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  };

  return (
    <Box>
      <Card>
        <CardHeader textAlign="left">
          <Heading size="md">Selected Products</Heading>
        </CardHeader>
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr key={0}>
                <Th>Product</Th>
                <Th isNumeric>Quantity</Th>
                <Th>Adjust (+/-)</Th>
              </Tr>
            </Thead>

            <Droppable droppableId="droppable2">
              {(provided, snapshot) => (
                <Tbody
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {selectedProducts.length === 0 && (
                    <Tr>
                      <Td colSpan={3}>
                        <DropZonePlaceholder />
                      </Td>
                    </Tr>
                  )}
                  {selectedProducts.map((product, index) => (
                    <Draggable
                      key={product.code}
                      index={index}
                      draggableId={product.code}
                    >
                      {(provided, snapshot) => (
                        <Tr
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <Td>
                            <Heading size="md">{product.description}</Heading>
                            <Text fontSize="sm" color="gray.600">SKU {product.code}</Text>
                          </Td>
                          <Td isNumeric>
                            <Input
                              value={quantities[product.code]}
                              onChange={(e) => updateQuantity(e, product.code)}
                            />
                          </Td>
                          <Td>
                            <Stack spacing={2}>
                              <IconButton
                                onClick={() => {
                                  increaseQuantity(product.code);
                                }}
                                colorScheme="teal"
                                aria-label="Add (+)"
                                icon={<AddIcon />}
                              />
                              <IconButton
                                onClick={() => {
                                  decreaseQuantity(product.code);
                                }}
                                colorScheme="teal"
                                aria-label="Remove (-)"
                                icon={<MinusIcon />}
                              />
                            </Stack>
                          </Td>
                        </Tr>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Tbody>
              )}
            </Droppable>
          </Table>
        </TableContainer>
      </Card>
      <Flex mt={5}>
        <Spacer />
        <Button colorScheme="red" variant="outline" onClick={generateOrder}>
          { isLoading ? (<>Loading...</>) : (<>Generate Order</>) }
        </Button>
      </Flex>
    </Box>
  );
}

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "white",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "white",
  padding: grid,
  width: "full",
});

const DraggableLists = ({ customer }) => {
  const [sourceItems, setSourceItems] = useState(
    customerTopProducts["top_products"]
  );
  const [selectedItems, setSelectedItems] = useState([]);

  const getList = (id) => {
    if (id === "droppable") return sourceItems;
    if (id === "droppable2") return selectedItems;
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        getList(source.droppableId),
        source.index,
        destination.index
      );

      if (source.droppableId === "droppable") setSourceItems(items);
      if (source.droppableId === "droppable2") setSelectedItems(items);
    } else {
      const result = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      );

      setSourceItems(result.droppable);
      setSelectedItems(result.droppable2);
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Flex mt={10}>
        <Stack spacing={8} direction="row" align="stretch">
          <Box textAlign="left">
            <SelectedCustomer customer={customer} />
            <TopProductsList sourceItems={sourceItems} />
          </Box>

          <Box>
            <SelectedProductsList
              selectedItems={selectedItems}
              customer={customer}
            />
          </Box>
        </Stack>
      </Flex>
    </DragDropContext>
  );
};

export default DraggableLists;
