import "./App.css"
import { Box, Heading } from "@chakra-ui/react"
import { Button, ButtonGroup, Flex } from '@chakra-ui/react'
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import { SearchIcon } from '@chakra-ui/icons'

import React, { useState, useRef } from "react";
import { customers } from './data/mock';

import DraggableLists from "./components/DraggableLists"


function App() {
  const userSearch = useRef(null);
  const [customer, setCustomer] = useState(customers["customers"][0]);
 
  const getUsers = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
      mode: "no-cors",
      headers: { Authentication: "Bearer b469bcaf-0091-43ec-b231-0091" },
    };
    console.log(userSearch.current.value);
    fetch(
      "https://tom-it.postman.co/workspace/6f87b490-4057-4ef3-8733-f096ffc777ab/mock/b469bcaf-0091-43ec-b231-5f04c6e08986/api/customer/test",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  };

  
  return (
    <div className="App">
      <Heading as="h1" mt={10}>
        Orders App
      </Heading>
      <Box ml={20} mr={20} mt={10}>
        <Box>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<SearchIcon color="gray.300" size="lg" />}
            />
            <Input placeholder="Search Customer" size="lg" ref={userSearch} />
          </InputGroup>
          <Button
            mt={5}
            colorScheme="teal"
            variant="outline"
            onClick={getUsers}
          >
            Get Users
          </Button>
        </Box>

        <DraggableLists customer={customers["customers"][0]} />
      </Box>
    </div>
  );
}

export default App;
