/*
GetCustomer API
*/
const customers = {
    "customers": [
      {
        "id": "420bc17c-e104-49e0-9c85-af927a6e589a",
        "code": "20038",
        "name": "Customer Name"
      }
    ]
  }

/*
GetCustomerTopProducts API
*/
const customerTopProducts = {
	"customer": {
		"id": "420bc17c-e104-49e0-9c85-af927a6e589a",
		"code": "20038",
		"name": "Customer Name"
	},
	"top_products": [{
		"qty": 30,
		"code": "ML-52670",
		"itemId": "1badc63d-970b-436a-26e1-57836e0ed3a2",
		"description": "Test Product 124"
	}, {
		"qty": 17,
		"code": "MSU-87490",
		"itemId": "cfc20d52-9391-49d1-8181-f77763ac3d59",
		"description": "Test Product 222"
	}]
}

/*
GetCustomerProducts API
*/
const customerProducts = {
	"products": [{
		"qty": 30,
		"code": "ML-52670",
		"itemId": "1badc63d-970b-436a-26e1-57836e0ed3a2",
		"description": "Test Product 124"
	}, {
		"qty": 17,
		"code": "MSU-87490",
		"itemId": "cfc20d52-9391-49d1-8181-f77763ac3d59",
		"description": "Test Product 222"
	}]
}

/*
CreateOrder API
*/
const createOrder = {
  "customer_id": "420bc17c-e104-49e0-9c85-af927a6e589a",
  "products": [
      {
       "product_id": "e5463560-4226-403c-853d-a0103bc12fdfg1231a",
       "quantity": 2
       },
       {
       "product_id": "e5463560-4226-403c-853d-a0103bc12fdfg1231a",
       "quantity": 2
       },
  ]
}



export {customers, customerTopProducts, customerProducts};